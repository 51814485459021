import React, { useState } from "react";
import CustomModal, { ModalInput } from "../Modal";
import { createCustomer, updateCustomer } from "../../utils/api";
import { Checkbox, MenuItem, OutlinedInput, Select } from "@mui/material";
import { toast } from "react-toastify";

const ClientModal = ({
  open,
  setOpen,
  reload,
  customers,
  update,
  setUpdate,
  groups,
  customer,
}) => {
  const [newCustomer, setNewCustomer] = useState({
    access: [],
  });
  const [searchAccess, setSearchAccess] = useState("");

  const [loading, setLoading] = useState(false);
  const createNewCustomer = async () => {
    if (
      !newCustomer.userName ||
      (!newCustomer.password && !customer) ||
      !newCustomer.phoneNumber
      // (!newCustomer.group && !customer)
    ) {
      toast.error("Please Fill All Fields");
      return;
    }
    const formdata = new FormData();
    setLoading(true);
    if (!update) {
      if (customer) {
        delete newCustomer.password;
        newCustomer.type = "Customer";
        formdata.append("userName", newCustomer.userName);
        formdata.append("phoneNumber", newCustomer.phoneNumber);
        formdata.append("group", newCustomer.group);
        formdata.append("type", "Customer");
        formdata.append("image", newCustomer.image);
        formdata.append("wagon", newCustomer.wagon);
        formdata.append("address", newCustomer.address);
        formdata.append("location", newCustomer.location);
        formdata.append("gstNumber", newCustomer.gstNumber);
      } else {
        newCustomer.type = "Client";
        newCustomer.orderAccess = newCustomer.orderAccess ? true : false;
      }
      const [success, data] = await createCustomer(
        customer ? formdata : newCustomer
      );
      if (success) {
        setOpen(false);
        setNewCustomer({});
        reload();
        toast.success("Customer Created Successfully");
      } else {
        toast.error(data.response.data.message);
      }
    } else {
      if (customer) {
        formdata.append("userName", newCustomer.userName);
        formdata.append("phoneNumber", newCustomer.phoneNumber);
        formdata.append("type", "Customer");
        formdata.append("image", newCustomer.image);
        formdata.append("wagon", newCustomer.wagon);
        formdata.append("group", newCustomer.group);
        formdata.append("address", newCustomer.address);
        formdata.append("location", newCustomer.location);
        formdata.append("gstNumber", newCustomer.gstNumber);
      } else {
        formdata.append("phoneNumber", newCustomer.phoneNumber);
        formdata.append("password", newCustomer.password);
        formdata.append("access", JSON.stringify(newCustomer.access));
        formdata.append("orderAccess", newCustomer.orderAccess);
        formdata.append("type", "Client");
      }

      const [success, data] = await updateCustomer(update._id, formdata);
      if (success) {
        setOpen(false);
        setUpdate(null);
        reload();
        toast.success("Customer Updated Successfully");
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (update) {
      setNewCustomer({
        ...update,
        access: update.access.map((item) => item._id),
        group: update?.group?._id,
      });
    }
  }, [update]);

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      onClick={createNewCustomer}
      loading={loading}
    >
      <ModalInput
        title={"USERNAME"}
        onChange={(e) => {
          setNewCustomer({ ...newCustomer, userName: e.target.value });
        }}
        value={newCustomer.userName}
      />
      {customer && (
        <ModalInput
          title={"ADDRESS"}
          onChange={(e) => {
            setNewCustomer({ ...newCustomer, address: e.target.value });
          }}
          value={newCustomer.address}
        />
      )}
      {customer && (
        <ModalInput
          title={"LOCATION"}
          onChange={(e) => {
            setNewCustomer({ ...newCustomer, location: e.target.value });
          }}
          value={newCustomer.location}
        />
      )}
      {customer && (
        <ModalInput
          title={"GSTIN"}
          onChange={(e) => {
            setNewCustomer({ ...newCustomer, gstNumber: e.target.value });
          }}
          value={newCustomer.gstNumber}
        />
      )}
      {!customer && (
        <ModalInput
          title={"PASSWORD"}
          onChange={(e) => {
            setNewCustomer({ ...newCustomer, password: e.target.value });
          }}
          value={newCustomer.password}
        />
      )}
      <ModalInput
        title={"Phone Number"}
        onChange={(e) => {
          setNewCustomer({ ...newCustomer, phoneNumber: e.target.value });
        }}
        value={newCustomer.phoneNumber}
      />

      <div className="flex gap-1 md:gap-3 flex-col md:flex-row md:items-center justify-between">
        <label htmlFor={"Group"}>City</label>
        <select
          value={newCustomer.group}
          name={"Group"}
          id={"Group"}
          className={`
       bg-transparent
        border-2 border-black md:w-64 py-1 `}
          onChange={(e) => {
            setNewCustomer({ ...newCustomer, group: e.target.value });
          }}
        >
          <option value="">Select</option>
          {groups?.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {customer && (
        <>
          <ModalInput
            type="file"
            title={"Image"}
            onChange={(e) => {
              setNewCustomer({ ...newCustomer, image: e.target.files[0] });
            }}
          />

          <ModalInput
            type="number"
            title={"Wagon"}
            onChange={(e) => {
              setNewCustomer({ ...newCustomer, wagon: e.target.value });
            }}
            value={newCustomer.wagon}
          />
        </>
      )}

      {!customer && (
        <>
          <div className="flex flex-col md:flex-row gap-1 md:gap-3 md:items-center justify-between">
            <label className="">Access</label>
            <div className="flex flex-col gap-2 w-full md:w-64">
              <input
                type="text"
                placeholder="Search access..."
                value={searchAccess}
                onChange={(e) => setSearchAccess(e.target.value)}
                className="border-2 border-black p-1"
              />
              <Select
                sx={{
                  width: "100%",
                  border: "2px solid black",
                  borderRadius: "0px",
                  "@media (max-width: 768px)": {
                    width: "100%",
                  },
                  "& .MuiSelect-select": {
                    padding: "0.5rem",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: "0px",
                  },
                }}
                multiple
                value={newCustomer.access ? newCustomer.access : []}
                onChange={(e) => {
                  setNewCustomer({ ...newCustomer, access: e.target.value });
                }}
                input={
                  <OutlinedInput
                    sx={{ bg: "white", borderRadius: "0px" }}
                    placeholder="Select Access"
                    id="select-multiple-chip"
                  />
                }
              >
                {customers
                  ?.filter(
                    (item) =>
                      item?.type === "Customer" &&
                      item.name
                        .toLowerCase()
                        .includes(searchAccess.toLowerCase())
                  )
                  ?.map((name) => (
                    <MenuItem
                      key={name._id}
                      value={name._id}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#fff101",
                        },
                      }}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div className="flex gap-1 md:gap-3 md:items-center flex-col md:flex-row justify-between ">
            <label htmlFor={"Order Access"}>Order Access</label>
            {/* <input
        onChange={onChange}
        type={type ? type : "text"}
        name={"Order Access"}
        id={"Order Access"}
        className={`bg-transparent border-2 md:w-64 border-black py-1  px-2`}
        value={newCustomer.orderAccess}
      /> */}
            <Checkbox
              checked={newCustomer.orderAccess}
              onChange={(e) => {
                setNewCustomer({
                  ...newCustomer,
                  orderAccess: e.target.checked,
                });
              }}
            />
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default ClientModal;
